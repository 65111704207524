import { requestHeaders } from './utils';

interface Body {
  message: string;
  level: 'info' | 'error';
  data: {
    [key: string]: string;
  }
}

const URL = '/log';

const log = async (body: Body) => {
  try {
    body.message = `Client side error :: ${body.message}`;

    fetch(URL, { ...requestHeaders, body: JSON.stringify(body) });
  } catch (error) {
    console.error('log :: log error :: now what lol ::', error);
  }
};

export default log;
