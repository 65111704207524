import React, { createContext, ReactNode } from 'react';

interface AssetBasePathProviderProps {
  value: string;
  children: ReactNode;
}

// Create the context with an initial value
export const AssetsBasePathContext = createContext<string>('');

const AssetBasePathProvider: React.FC<AssetBasePathProviderProps> = ({ value, children }) => {
  return <AssetsBasePathContext.Provider value={value}>{children}</AssetsBasePathContext.Provider>;
};

export default AssetBasePathProvider;
