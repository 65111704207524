import React from 'react';

import '../styles/not-found.scss';

// Note that for some error handling in the server we send this directly so don't add context unless you want to add to the server
export default function NotFound() {
  return (
    <div className='not-found-page'>
      <h1>Oops! That page does not exist? </h1>
      <p> {"We can't seem to find the page you're looking for. But don't worry, we can help guide you back."} </p>
      <a href='/'> Take me home </a>
    </div>
  );
}
