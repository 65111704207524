import React, { useState, useContext } from 'react';
import LocationTile from '../components/tiles';
import { AssetsBasePathContext } from '../components/assetBasePathProvider';
import locationsData from '../locations/all.json';

const HomePage = () => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const assetBasePath = useContext(AssetsBasePathContext);

  return (
    <section className="page">
      <input
        type="text"
        placeholder="Search locations..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-bar"
      />
      <div className="location-grid">
        {
          locationsData.locations.filter(location => location.title.toLowerCase()
            .includes(searchTerm.toLowerCase()))
            .map(location => (
              <LocationTile
                key={location.id}
                image={`${assetBasePath}/assets/${location.icon}.webp`}
                title={location.title}
                highlights={location.highlights}
                country={location.country}
                city={location.city}
              />
            ))
        }
      </div>
    </section>
  );
};

export default HomePage;
