import React from 'react';

const Health = () => {
  return (
    <section>
      <h1>Healthy</h1>
    </section>
  );
};

export default Health;
