import React, {createContext} from 'react';
import {Helmet} from 'react-helmet';

const PageSeoContext = createContext();

export const PageSeoProvider = ({children, seo = {}, clientCanonicalPath}) => {
  const {title, description, keywords} = seo;

  return (
    <PageSeoContext.Provider value={seo}>
      <Helmet>
        <title>{title}</title>
        <meta name='description' data-react-helmet={true} content={description} />
        <meta name='keywords' data-react-helmet={true} content={keywords} />
        <link rel='canonical' data-react-helmet={true} href={`https://itsadigitallife.com${clientCanonicalPath}`} />
        <meta property='og:url' content={`https://itsadigitallife.com${clientCanonicalPath}`} />
        <meta property='og:title' content={seo['og:title']} />
        <meta property='og:description' content={seo['og:description']} />
      </Helmet>
      {children}
    </PageSeoContext.Provider>
  );
};
