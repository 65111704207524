import React from 'react';

const AboutUs = () => {
  return (
    <>
      <section>
        <div className='container'>
          <h2>About Us</h2>
          <p style={{lineHeight: '1.25', fontSize: '1.25em'}}>
            {`
            At Digital Nomad Life, we're passionate about the freedom and flexibility that remote work offers. Our team, comprising seasoned remote
            workers, digital nomads, and freelancers, has traversed the globe, working from beaches, mountains, and bustling cities. This diversity of
            experiences fuels our commitment to help others navigate the remote work lifestyle.
            `}
          </p>
          <p style={{lineHeight: '1.25', fontSize: '1.25em'}}>
            {`
            With backgrounds in technology, travel, and lifestyle writing, we've encountered firsthand the challenges and rewards of remote work.
            Digital Nomad Life was born out of a desire to share our knowledge, tips, and resources to empower others to live and work wherever they
            choose. Our mission is simple: to make remote work accessible, sustainable, and enjoyable for everyone.
            `}
          </p>
          <p style={{lineHeight: '1.25', fontSize: '1.25em'}}>
            {`Whether you're a seasoned digital nomad, contemplating your first remote job, or looking to improve your work-from-home setup, we're here
            to support you. Our community is dedicated to sharing the latest remote jobs, productivity hacks, and travel tips to help you achieve the
            perfect balance of work and life, anywhere in the world. If you're interested in joining our movement or contributing to our platform,
            we'd love to hear from you. Together, let's redefine what it means to work and live on our own terms. 🌍✈️💻`}
          </p>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
