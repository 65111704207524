import React, {useState} from 'react';

const Contact = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = event => {
    event.preventDefault();
    // Implement form submission logic here
  };

  return (
    <section className='signup'>
      <h2>Get in Touch!</h2>
      <div className='form-wrapper'>
        <form onSubmit={handleSubmit} className='page-form'>
          <input className='form-input' type='email' required value={email} onChange={e => setEmail(e.target.value)} placeholder='Email' />
          <textarea className='form-input' required value={message} onChange={e => setMessage(e.target.value)} placeholder='Message' />
        </form>
      </div>
    </section>
  );
};

export default Contact;
