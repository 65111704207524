import React from 'react';

const Head = ({assetsBasePath, seo, canonicalPath}) => {
  const gtmConfigInlineScript = `
    window.addEventListener('load', function() {
      var gtmScript = document.createElement('script');
      gtmScript.src = '${assetsBasePath}/assets/google-tag-manager.js';
      gtmScript.async = true;
      document.body.appendChild(gtmScript);
    });
  `;
  return (
    <head>
      <meta charSet='utf-8' />
      <meta name='viewport' content='width=device-width, initial-scale=1' />
      <link rel='preload' href={`${assetsBasePath}/css/main.css`} as='style' />
      <link rel='preload' href={`${assetsBasePath}/js/main.js`} as='script' />
      <link rel='preload' href={`${assetsBasePath}/js/vendors.js`} as='script' />
      <link rel='stylesheet' href={`${assetsBasePath}/css/main.css`} />
      <title>{seo.title}</title>
      <link rel='canonical' data-react-helmet={false} href={`https://itsadigitallife.com${canonicalPath}`} />
      <meta name='description' data-react-helmet={true} content={seo.description} />
      <meta name='keywords' data-react-helmet={true} content={seo.keywods} />
      <link rel='icon' href={`${assetsBasePath}/assets/favicon.ico`} type='image/x-icon' />
      <link rel='shortcut icon' href={`${assetsBasePath}/assets/favicon.ico`} type='image/x-icon' />
      <meta property='og:type' content='website' />
      <meta property='og:url' content={`https://itsadigitallife.com${canonicalPath}`} />
      <meta property='og:image' content={`${assetsBasePath}/assets/itsadigitallifelogo.webp`} />
      <meta property='og:title' data-react-helmet={true} content={seo['og:title']} />
      <meta property='og:description' data-react-helmet={true} content={seo['og:description']} />
      <link rel='preconnect' href='https://www.google-analytics.com' />
      <link rel='preconnect' href='https://www.googletagmanager.com' />
      <link rel='preconnect' href='https://assets.itsadigitallife.com' />
      <script async defer src='https://www.googletagmanager.com/gtag/js?id=G-QMVC87R9WC'></script>
      <script dangerouslySetInnerHTML={{__html: gtmConfigInlineScript}}></script>
    </head>
  );
};

export default Head;
