import React from 'react';
import faqsList from '../content/faqs';

const FAQS = () => {
  return (
    <>
      <section>
        <div className='container'>
          <h2>Frequently Asked Questions</h2>
          {faqsList.map((faq, i) => (
            <div key={i}>
              <h4>{faq.question}</h4>
              <p>{faq.answer}</p>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default FAQS;
