import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import routes from './routes';

// Context
import AssetBasePathProvider from './components/assetBasePathProvider';
import {PageSeoProvider} from './context/seo';

// Components
import Head from './components/head';
import Navigation from './components/navigation';

const assetsBasePath = window.__ASSET_BASE_PATH__;
const pageSeo = window.__PAGE_SEO__;
const canonicalPath = window.__CANONICAL__;

const Layout = ({children, seo, clientCanonicalPath}) => {
  return (
    <PageSeoProvider seo={seo} clientCanonicalPath={clientCanonicalPath}>
      {children}
    </PageSeoProvider>
  );
};

function ClientApp() {
  return (
    <html lang='en'>
      <Head assetsBasePath={assetsBasePath} seo={pageSeo} canonicalPath={canonicalPath} />
      <body>
        <AssetBasePathProvider value={assetsBasePath}>
          <Router>
            <Navigation />
            <Routes>
              {routes.map(({path, component: Component, seo}, index) => (
                <Route
                  key={index}
                  path={path}
                  element={
                    <Layout seo={seo} clientCanonicalPath={path}>
                      <Component />
                    </Layout>
                  }
                />
              ))}
            </Routes>
          </Router>
        </AssetBasePathProvider>
      </body>
    </html>
  );
}

export default ClientApp;
