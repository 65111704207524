import React from 'react';
import termsList from '../content/terms';

const Terms = () => {
  return (
    <>
      <section>
        <div className='container'>
          <h1>Terms & Conditions</h1>
          {termsList.map((terms, i) => (
            <div key={i}>
              {terms.body.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default Terms;
